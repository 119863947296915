@import '../../styles/propertySets.css';

.container {
}
.modalTitle {
  text-align: center;
  max-width: 340px;
  padding-bottom: 24px;
  margin: 0 auto 60px;
  font-size: 24px;
  line-height: 28px;
  font-weight: var(--fontWeightBold);
  color: var(--matterColor);
  border-bottom: 8px solid var(--marketplaceColor);
}

.icon {
  display: none;
}

.label {
  max-width: 380px;
  padding: 0;
  margin: 0 auto;
  font-size: 16px;
  line-height: 18px;
  color: var(--matterColor);
}

.input {
  max-width: 380px;
  margin: 0 auto 43px;
}

.buttonContainer {
  margin: 0 auto;
  max-width: 380px;
}
.nextButton {
  align-self: center;
  margin: 0 auto;
  padding: 16px 173px;
  color: #fff;
  background-color: var(--marketplaceColor);
  border: none;
  border-radius: 10px;

  cursor: pointer;
  &:hover {
     background-color: #1062AD;
   }
}
