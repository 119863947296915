@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.signupFormInput {
  position: relative;
  & > label {
    margin-bottom: 0;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
  min-width: 185px;
  margin-right: 26px;
}

.lastNameRoot {
  width: calc(66% - 9px);
  min-width: 204px;
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  margin-top: 35px;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;

  margin-top: 0;
  margin-bottom: 36px;
  line-height: 1;
}

.bottomWrapperButton {
  max-width: 285px;
  margin: 0 auto;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.discountFieldRadioButton {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.discountFieldRadioInput {
  display: none;

  &:checked + .discountFieldRadioBox {
    background-color: var(--matterColorNegative);
  }
}

.discountFieldRadioBox {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  border: 2px solid var(--matterColor);
  border-radius: var(--borderRadius);
}
