@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.darkSkin {
  background-color: var(--marketplaceColor);
}

.tab {
  margin-left: 0;
  margin-right: auto;
}

.tabContent {
  @apply --marketplaceLinkStyles;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  /* Font */
  @apply --marketplaceTabNavHorizontalFontStyles;

  font-size: 16px;
  color: var(--matterColor);
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    border-bottom-width: 8px;
    padding: 16px 10px 14px 10px;
  }
}

.tabContentInbox {
  @apply --marketplaceLinkStyles;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;

  /* Font */
  @apply --marketplaceTabNavHorizontalFontStyles;

  font-size: 18px;
  font-style: normal;
  color: var(--matterColor);
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    border-bottom-width: 8px;
    padding: 16px 33px 14px 2px;
  }
}


.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: var(--marketplaceColor);
  border-bottom-width: 5px;
  color: var(--matterColorDark);
}

.selectedTabInbox {
  border-bottom-width: 8px;
  color: var(--marketplaceColor);
  font-weight: 700;
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--matterColorLight);

  &:hover {
    color: var(--matterColorLight);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);
}

.disabledDarkSkin {
  color: var(--matterColorDark);
  opacity: 0.3;
}
