.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColor);
}

.discountInputLabel {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
}

.discountFieldContainer {
  position: relative;
  max-width: 500px;
  cursor: pointer;
}

.discountFieldArrow {
  position: absolute;
  right: 5px;
  bottom: 11px;
}

.discountSelectModalOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(196, 196, 196, 0.2);
  backdrop-filter: blur(10px);
}

.discountSelectModal {
  display: flex;
  flex-basis: 488px;
  flex-direction: column;
  align-items: center;
  padding: 55px 58px 27px;
  background: var(--matterColorLight);
  border: 2px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusModal);
  box-shadow: var(--boxShadowModal);
}

.discountHeading {
  width: 190px;
  margin: 0 0 16px;
  padding-bottom: 13px;
  font-weight: var(--fontWeightMedium);
  font-size: 30px;
  line-height: 35px;
  color: var(--matterColor);
  text-align: center;
  border-bottom: 8px solid var(--marketplaceColor);
}

.discountLabel {
  margin: 0 0 25px;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColor);
}

.discountList {
  display: grid;
  grid-template-rows: repeat(6, 16px);
  grid-auto-flow: column;
  gap: 24px 66px;
  align-items: center;
  width: fit-content;
  margin: 0 0 62px;
}

.discountFieldRadioButton {
  display: flex;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.discountFieldRadioInput {
  display: none;

  &:checked + .discountFieldRadioBox {
    background-color: var(--matterColorNegative);
  }
}

.discountFieldRadioBox {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  border: 2px solid var(--matterColor);
  border-radius: var(--borderRadius);
}

.discountFieldRadioLabel {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
}

.discountSelectBottomWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.discountSelectButton {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColor);
  text-transform: uppercase;
  cursor: pointer;
}

.discountSelectButtonHighlight {
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 500px;
    margin-top: 113px;
  }
}
