.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 23px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 37px;
}

.reviewContent {
  flex-shrink: 0;

  & label {
    margin: 0 0 20px;
    padding: 0;
    line-height: 32px;
    font-weight: var(--fontWeightMedium);
  }
}

.submitButton {
  margin: 0;
  flex-shrink: 0;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
  }
}
