@import '../../styles/propertySets.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}

.messageItem {
  margin-bottom: 15px;

  /* Clearfix */
  @apply --clearfix;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: row;
}

.ownMessage {
  float: right;
}

.avatar {
  flex-shrink: 0;
  margin: 0px 12px 0 0;

  @media (--viewportMedium) {
    margin: 0px 12px 0 0;
  }
}

.ownMessageContentWrapper {
  @apply --clearfix;
}

.messageContent,
.ownMessageContent {
  @apply --marketplaceMessageFontStyles;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
}

.ownMessageContent {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  float: right;
}

.messageDate,
.ownMessageDate {
  @apply --marketplaceMessageDateFontStyles;

  margin: 11px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;
}

.transitionItem {
  margin-bottom: 15px;

  /* Clearfix */
  @apply --clearfix;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  @apply --marketplaceTxTransitionFontStyles;
  margin: 0;
  font-size: 18px;
  line-height: 32px;
}

.transitionDate {
  @apply --marketplaceMessageDateFontStyles;
  color: var(--matterColorAnti);
  margin: 0;

  font-size: 16px;
  line-height: 22px;
  color: var(--matterColorMedium);
}

.bullet {
  margin: 0 10px;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;
  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 18px 0 4px;
  

  @media (--viewportMedium) {
    margin: 18px 0 5px;
  }
}

.reviewStar {
  width: 22px;
  height: 22px;
  margin-right: 3px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.showOlderButton {
}

.leaveAReviewSecond {
  display: block;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
}
