@import '../../styles/propertySets.css';

.coverEverything {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.root {
  /* Positioning */
  composes: coverEverything;
}

.overlay {
  /* Positioning */
  composes: coverEverything;

  /* Overlay background style */
  background-color: var(--matterColorDark);
  opacity: 0.5;
}

.overlayContent {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorLight);
  line-height: 120%;

  /* Positioning */
  composes: coverEverything;

  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media (--viewportMedium) {
  .overlayContent {
    margin-top: 83px;
    margin-bottom: 0;
  }
}

.message {
  max-width: 220px;
  margin: auto 0;
  text-align: center;
  color: var(--matterColorLight);
  line-height: 19px;
}

.errorMessage {
  composes: message;
  color: var(--failColor);
}
