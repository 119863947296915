@import '../../styles/propertySets.css';

.root {
  padding-right: 63px;
}

.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 33px;
  width: 88px;
  height: 88px;
}

.info {
  flex: 1;
}

.heading {
  @apply --marketplaceH2FontStyles;
  margin: 20px 0 0 0;

  @media (--viewportMedium) {
    margin: 30px 0 0 0;
  }
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  color: var(--matterColor);
  @apply --marketplaceH4FontStyles;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMore {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0 0 0 5px;
}

.links {
  @apply --marketplaceH4FontStyles;
  display: flex;
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
  & .link {
    color: var(--matterColor);
    font-weight: var(--fontWeightSemiBold);
    white-space: nowrap;
    text-decoration: none;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 30px;
  font-size: 26px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  @apply --marketplaceH4FontStyles;
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  @apply --marketplaceH4FontStyles;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin: 0;
  &:hover {
    text-decoration: none;
  }
}
