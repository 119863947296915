@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.profileInfoContainer {
  display: flex;
  flex-direction: column;
}

.avatar {
  flex-shrink: 0;
  margin-right: 21px;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  white-space: pre-line;
  margin: 0 0 21px;
  transition: all 1s ease-in;
  color: var(--matterColor);
  padding-right: 33px;
  padding-left: 30px;
  font-style: italic;
}

.reviewInfo {
  @apply --marketplaceH5FontStyles;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 30px;
  font-size: 16px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 15px;
  background-color: var(--matterColorAnti);
  border-radius: var(--borderRadiusCircle);
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline;
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 22px;
  width: 22px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.answerButtonContainer {
  text-align: right;
  margin-top: 27px;
  padding-right: 63px;
}

.answerButton {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);

  cursor: pointer;

  padding: 16px 32px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadius);
  display: inline-block;

&:hover {
   background-color: var(--marketplaceColorHover);
 }
}
