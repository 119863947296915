@import '../../styles/propertySets.css';

.root {
}

.password {
  @apply --marketplaceModalPasswordMargins;

  margin-top: 50px;
}

.passwordConfirmation {
  @apply --marketplaceModalPasswordMargins;

  margin-bottom: 72px;

  & > label {
    margin-bottom: 0;
    padding-bottom: 4px;
  }
}

.validationErrorMessage {
  position: relative;
  top: 0;
}

.submitButton {
  max-width: 355px;
  margin: 0 auto;
}
