@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.container {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 0 32px;

  @media (--viewportMedium) {
    flex-basis: 675px;
    max-width: 580px;
    min-height: auto;
    height: auto;
  }
}

.header {
  padding: 32px 0 26px;
  text-align: center;
  border-bottom: 1px solid var(--matterColorNegative);
}

.title {
  margin: 0;
  font-size: 22px;
  line-height: 26px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 32px 0 32px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelButton {
  width: auto;
  font-weight: 400;
  color: var(--marketplaceColor);
  background-color: transparent;

  &:hover {
    background-color: inherit;
  }

  &:focus {
    background-color: inherit;
  }
}

.saveButton {
  width: auto;
  padding: 16px 44px;
}
