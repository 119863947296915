@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.heartIcon {
  color: white;
  position: absolute;
  right: 20px;
  bottom: 12px;
  width: 28px;
  height: 24px;
}

.closeIcon {
  stroke: none;
  color: white;
  position: absolute;
  right: 14px;
  top: 14px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.discountIndicator {
  position: absolute;
  top: 21px;
  left: 0;
  padding: 10px 9px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: var(--matterColorLight);
  border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
  background-color: var(--ratingFilledStarColor);
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
