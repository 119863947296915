@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  height: 317px;
  width: 387px;

  @media (--viewportMedium) {
    flex-basis: 387px;
    margin: 0;
    padding: 0;
  }
}

.modalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 23px 30px 10px 26px;
}

.modalButtonsWrapper {
  display: flex;
  justify-content: space-between;
}

.modalButton {
  border: none;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
  &:disabled {
    color: var(--matterColorGrey);
    cursor: default;
  }
}

.reasonsListWrapper {
  padding-left: 22px;
  margin-bottom: 12px;
}

.reasonsListContent {
  margin-top: 0;
  margin-bottom: 20px;
}

.reasonListItem {
  cursor: pointer;
  line-height: 34px;
}

.reasonListItemActive {
  color: var(--marketplaceColor);
  font-weight: 700;
  position: relative;
}

.otherReasonTitle {
  cursor: pointer;
}

.checkmarkIcon {
  position: absolute;
  top: 4px;
  left: -30px;
  transform: scale(0.5);
}

.otherInput {
  width: 280px;
  &:disabled {
    border-color: #E1E1E1;
  }
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.isOpenClass {
  position: fixed;
  top: 236px;
  right: 0px;
  z-index: 100;

  @media (--viewportMedium) {
    top: 350px;
    right: 430px;
  }

  & .scrollLayer {
    @apply --marketplaceModalRootStyles;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 675px;
      min-height: auto;
      height: auto;
    }
  }

  @media (--viewportLarge) {
    flex-basis: 675px;
    min-height: auto;
    height: auto;
  }
}

.scroll {    
  width: 387px;
  height: 317px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}
