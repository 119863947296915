.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.currencyInputLabel {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
}
