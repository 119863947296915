@import '../../styles/propertySets.css';

.root {
& p {
    @apply --marketplaceH4FontStyles;
    margin-top: 0;
  }
& h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin-top: 35px;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 8px;
  }

& .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

@media (--viewportMedium) {
  margin-top: 0;
  margin-bottom: 54px;
}
}
}


