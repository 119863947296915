@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.container {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 0 32px;

  @media (--viewportMedium) {
    flex-basis: 675px;
    max-width: 580px;
    min-height: auto;
    height: auto;
  }
}

.header {
  position: relative;
  padding: 32px 0 26px;
  text-align: center;
  border-bottom: 1px solid var(--matterColorNegative);
}

.deleteButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  min-width: 57px;
  height: 23px;
  min-height: auto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: var(--failColor);
  text-decoration: underline;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    background-color: inherit;
  }

  &:focus {
    text-decoration: underline;
    background-color: inherit;
  }
}

.title {
  margin: 0;
  font-size: 22px;
  line-height: 26px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 32px 0 32px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelButton {
  width: auto;
  font-weight: 400;
  color: var(--marketplaceColor);
  background-color: transparent;

  &:hover {
    background-color: inherit;
  }
  
  &:focus {
    background-color: inherit;
  }
}

.saveButton {
  width: auto;
  padding: 16px 44px;
}
