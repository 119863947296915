@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 52px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  @media (--viewportSmall) {
    flex-direction: row;
    width: 100%;
    max-width: 550px;
  }

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.downloadQRCode {
  flex-grow: 1;
  max-width: 280px;
  max-height: 280px;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    width: 0;
    margin-right: 50px;
  }
}

.linksContainer {
  flex-grow: 1;
  width: 100%;

  @media (--viewportSmall) {
    width: 0;
  }
}

.downloadLink {
  @apply --marketplaceButtonFontStyles;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0 16px;
  color: var(--matterColorLight);
  background: linear-gradient(81.02deg, #215564 -201.24%, #388D9B 131.3%);
  border-radius: var(--borderRadius);

  &:hover {
    text-decoration: none;
  }
}

.downloadLinkIcon {
  position: absolute;
  left: 16px;
  fill: var(--matterColorLight);
}

.downloadLink:first-child {
  margin-bottom: 25px;
}

.hint {
  @apply --marketplaceH2FontStyles;
  margin-top: 0;
}
