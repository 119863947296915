@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.loginFormInput {
  & > label {
    margin-bottom: 0;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;

  margin-top: 42px;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  margin-top: 51px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;

  margin-top: 0;
  margin-bottom: 36px;
}

.bottomWrapperButton {
  max-width: 285px;
  margin: 0 auto;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
  font-weight: var(--fontWeightSemiBold);
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperLink;
}
