@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.additionalLabel {
  @apply --marketplaceTinyFontStyles;
  margin-top: 34px;
  margin-bottom: 8px;
}

.additionalInput {
  @apply --marketplaceTinyFontStyles;
  line-height: 50px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
}

.cancelButton {
  border: none;
  cursor: pointer;
  @apply --marketplaceTinyFontStyles;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.submitButton {
  cursor: pointer;
  @apply --marketplaceTinyFontStyles;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  border: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
}

input, label {
  display: inline-block;
  border-bottom-color: var(--ratingFilledStarColor);
  &:hover {
     border-bottom-color: var(--ratingFilledStarColor);
   }
}

.checkBoxSvg path:nth-child(2) {
  stroke: var(--matterColor) !important;
  &:hover {
     stroke: var(--matterColor) !important;
   }
}
.checkBoxSvg path:nth-child(1) {
  fill: var(--matterColorNegative);
  stroke: none !important;
}
