@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.container {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 0 32px;

  @media (--viewportMedium) {
    flex-basis: 675px;
    max-width: 580px;
    min-height: auto;
    height: auto;
  }
}

.header {
  padding: 32px 0 26px;
  text-align: center;
  border-bottom: 1px solid var(--matterColorNegative);
}

.title {
  margin: 0;
  font-size: 22px;
  line-height: 26px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px 0 40px;
}

.listContainer {
  @apply --marketplaceTabNavFontStyles;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.imageContainer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: var(--borderRadius);
  background-color: var(--noImageBackgroundColor);
  overflow: hidden;
}

.emptyListImage {
  width: 56px;
  height: 56px;
}

.listImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newListImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorLight);
}

.newListIcon {
  fill: var(--matterColorNegative);
}

.iconContainer {
  flex-shrink: 0;
  
  width: 24px;
  margin-left: auto;
}

.iconSelected {
  padding: 6px 0px 0 6px;
}
