.root {
  position: relative;
}

.label {
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  max-height: 29px;
}

.inputContainer {
  position: relative;
  user-select: none;
}

.showPasswordControl {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
