@import '../../styles/propertySets.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  width: 100%;
  padding: 10px 10px 42px;
  background-color: var(--matterColorBright);

  @media (--viewportMobile) {
    padding: 12px;
  }
  @media (--viewportMedium) {
    padding: 0 24px 14px;
  }

  @media (--viewportLarge) {
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportLarge) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 249px;
  @media (--viewportMedium) {
    margin: 0 auto;
    padding-top: 18px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 18px 36px 0;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 18px 0 0;
  }
}

.someLinks {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 24px;
  }
}

.someLinksMobile {
  margin: 0 auto;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  width: 32px;
  height: 32px;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: 0.2s;

  &:hover {
     border: 2px solid var(--matterColor);
  }

  &:not(:last-child) {
    margin-right: 42px;
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMobile) {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    max-height: 231px;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 298px;
  margin-bottom: 0;

  @media (--viewportMobile) {
    flex-basis: 157px;
    padding-top: 35px;
  }
  @media (--viewportMedium) {
    padding-top: 0;
    display: block;
    order: 1;
    margin-right: 12px;
    margin-bottom: 0;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 10px;

  @media (--viewportMobile) {
    margin-bottom: 20px;
  }
  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  max-width: 89px;
  height: 45px;

  @media (--viewportMedium) {
    max-width: 154px;
    height: 84px;
  }
}

.organizationInfo {
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 23px;
  }
}
.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 23px;
}

.addressContainer {
  display: none;
  margin-left: -8px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.addressContainerMobile {
  display: flex;
  max-width: 230px;
}

.addressLink {
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColorMedium);

  &:hover {
    text-decoration: none;
  }
}

.organizationCopyright {
  font-size: 14px;
  margin-top: 23px;
}

.copyrightLink {
  transition: var(--transitionStyleButton);
  white-space: nowrap;
  font-size: 16px;
  line-height: 32px;
  color: var(--matterColorAnti);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinksContainer {
  display: flex;
  flex-direction: column;
  order: 2;
  margin: 0 auto;

  @media (--viewportMobile) {
    padding-top: 97px;
  }
  @media (--viewportMedium) {
    padding-top: 0;
    margin: 0;
  }
}

.infoLinksWrapper {
  display: flex;
}

.infoLinks {
  flex-basis: 165px;
  max-width: 55px;
  margin-right: 62px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
    padding-top: 54px;
    margin-right: 80px;
  }

  &:not(:first-child) {
     margin-right: 48px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  white-space: nowrap;
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 12px;
  }
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyle);
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: var(--fontWeightRegular);

  &:hover {
    text-decoration: none;
    font-weight: var(--fontWeightBold);
   }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.aboutUs {
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    font-weight: bold;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
}
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 90px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  font-size: 16px;
  line-height: 32px;
  white-space: nowrap;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.legalLinkMobile {
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:hover {
     color: var(--marketplaceColor);
     text-decoration: none;
   }
}

.legalLinkItem {
  text-align: right;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.privacy {
  margin-bottom: 48px;
}

/* Mobile: copyright mark and address link for mobile */
.copyrightAnnAddressMobile {
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  margin-top: 0;
  margin-bottom: 0;
  line-height: 32px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  margin: 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.privacy {
  margin-right: 24px;
}
