@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 72px;
  padding: 17px 33px;
  margin-top: 20px;
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 50px;
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
}

.container {
  flex-grow: 1;
  cursor: pointer;

  &:not(:first-child) {
     border-left: 2px solid #f2f2f2;
     padding-left: 20px;
   }
}

.inputContainer {
  background-color: transparent;
}

.input {
  padding: 0;
  border-bottom: none;
  background-color: transparent;
}


.label {
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColor);
  font-weight: 500;
  padding: 0;
}

.placeholder {
  font-size: 16px;
  line-height: 19px;
  color: #AAA9A9;
  font-weight: 500;
}

.searchButton {
  align-self: center;
  width: 137px;
  height: 54px;
  color: white;
  background: var(--marketplaceColor);
  border: none;
  border-radius: 40px;

  cursor: pointer;
  &:hover {
     background-color: #1062AD;
   }
}

.searchIcon {
  stroke: #fff;
  fill: #fff;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
