@import '../../styles/propertySets.css';

.wrapper {
  visibility: hidden;
}

.visible {
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: rgba(196, 196, 196, 0.2);
  z-index: 10;
  position: absolute;
  top: 191px;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  position: absolute;
  top: 30px;
  display: flex;
  justify-content: center;
  width: 488px;
  background-color: var(--matterColorLight);
  border-radius: 8px;
  padding: 57px 0 29px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modalContentWrapper {
  width: 334px;
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
  width: 100%;
  border-bottom: 8px solid var(--marketplaceColor);
  padding-bottom: 21px;
  text-align: center;
  color: var(--matterColor);
  margin-bottom: 32px;
}

.modalHint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
  margin-bottom: 32px;
}

.errorMessage {
  padding-top: 10px;
  color: var(--failColor);
}

.loadingIcon {
  padding-top: 10px;
  text-align: center;
}

.modalContentContainer {
  max-height: 650px;
  border: 1px solid red;
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding-top: 57px;
  padding-bottom: 29px;

@media (--viewportMedium) {
  flex-basis: 488px;
  min-height: auto;
  height: auto;
  margin-top: 8vh;
}
}

.backgroundBlur {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  backdrop-filter: blur(10px);
}

.scrollLayer {
  @apply --marketplaceModalRootStyles;

  /* Add default background color to avoid bouncing scroll showing the
 page contents from behind the modal. */
  background-color: var(--matterColorLight);

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

@media (--viewportMedium) {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: none;
}
}
