@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
    margin-bottom: 20px;
    margin-top: 0;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin-bottom: 35px;
    margin-top: 35px;
    font-size: 22px;
    line-height: 32px;
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.downloadLinksContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
