@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 21px 0 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 3px;
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0 0 19px;
  padding: 0;
  color: var(--matterColorAnti);
  font-size: 16px;
  line-height: 19px;
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;

  margin-bottom: 11px;
  font-size: 18px;
  line-height: 21px;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 20px 0 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 20px 0 0;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightRegular)
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  margin: 0 0 0 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightRegular)
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 2px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 24px 0 0px 0;
  }
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightBold);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 4px 0 14px;
  padding: 0;
  line-height: 24px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 16px 0 48px;
  }
}
