.root {
  position: relative;
}

.label {
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;
  max-width: 500px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}
