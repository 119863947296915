@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 70px 81px;

  @media (--viewportMedium) {
    flex-basis: 500px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;

  line-height: 38px;
  font-weight: var(--fontWeightMedium);
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;

  margin-top: 17px;
  font-weight: var(--fontWeightRegular);
}
