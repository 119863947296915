@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;
}

.email {
  margin-top: 37px;

  & > label {
    margin-bottom: 0;
    padding-bottom: 4px;
  }

  @media (--viewportMedium) {
    margin-top: 38px;
  }
}

.error {
  @apply --marketplaceModalErrorStyles;

  margin: 0;
  text-align: center;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  margin-top: 24px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.bottomWrapperButton {
  max-width: 355px;
  margin: 46px auto 0;
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}
