@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: center;
  margin-right: 15px;
  margin-left: 5px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
}

.notSelectedLabel {
  color: var(--matterColorAnti);
  position: relative;
}
