.languageSwitcher {
  width: 48px;
  color: #8C8D8D;
  padding: 0;
  border-bottom: 0;
}

.switcherWrapper {
  padding-left: 8px;
}
