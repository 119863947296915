.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0 94px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 60px;
    margin-top: 0;
    padding: 1px 0 7px 0;
  }
}

.qwe {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 43px;
}
